import { UseFormSetError } from 'react-hook-form';
import * as yup from 'yup';

export const step1Schema = yup.object().shape({
  firstName: yup.string().required('Имя должно быть заполнено'),
  lastName: yup.string().required('Фамилия должна быть заполнена'),
  // middleName: yup.string().required('Отчество должно быть заполнено'),
  inn: yup.string().typeError('ИНН должен быть заполнен').required('ИНН должен быть заполнен'),
  birthDate: yup
    .string()
    .typeError('Дата рождения должна быть заполнена')
    .required('Дата рождения должен быть заполнена'),
});

export const checkName = (
  name: string | null,
  field: string,
  fieldRus: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const pattern = /^(?!.*[ \-']{2})(?!.*([А-Яа-яЁё])\1{2})[А-Яа-яЁё][А-Яа-яЁё',\- ]*[А-Яа-яЁё]$/;
  if (!name) {
    setError(field, {
      type: 'manual',
      message: `${fieldRus} содержать не менее 2 и не более 200 символов`,
    });
    toastErrors.push(`${fieldRus} содержать не менее 2 и не более 200 символов`);
  } else {
    name = name?.trim();
    if (name.length < 2) {
      setError(field, {
        type: 'manual',
        message: `${fieldRus} содержать не менее 2 и не более 200 символов`,
      });
      toastErrors.push(`${fieldRus} содержать не менее 2 и не более 200 символов`);
    }
    if (!pattern.test(name)) {
      setError(field, {
        type: 'manual',
        message: `Допустимые символы для ввода [А-Я а-я ' -] без тройных повторов, минимальная длина 2 символа`,
      });
      toastErrors.push(
        `Допустимые символы для ввода [А-Я а-я ' -] без тройных повторов, минимальная длина 2 символа`
      );
    }
    if (name.length === 2 && name[0].toLocaleLowerCase() === name[1].toLocaleLowerCase()) {
      setError(field, {
        type: 'manual',
        message: `Допустимые символы для ввода [А-Я а-я ' -] без тройных повторов, минимальная длина 2 символа`,
      });
      toastErrors.push(
        `Допустимые символы для ввода [А-Я а-я ' -] без тройных повторов, минимальная длина 2 символа`
      );
    }
  }
  return toastErrors;
};
