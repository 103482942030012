import { CloseBurgerIcon } from 'assets/icons';
import { ReactComponent as IconExit } from 'assets/icons/icon-exit.svg';
import { ReactComponent as IconLeftArrow } from 'assets/left-arrow.svg';
import { ReactComponent as MProLogoWhite } from 'assets/mpro-logo-white.svg';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { getModalState } from 'reduxStore/reducers/ModalSlice/selectors/getModaState';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { profileActions } from 'reduxStore/reducers/ProfileSlice';
import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { getProfileState } from 'reduxStore/reducers/ProfileSlice/selectors/getProfileState';
import { getSubcontractorModerationFormStatus } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorModerationFormStatus';
import { IS_MEDIUM_DEVICE, IS_SMALL_DEVICE } from 'utils/settings';
import { logoutHandler } from '../../utils/helpers';
import { SIDEBAR_OPEN_LOCALSTORAGE_NAME, navLinks, textMotionVariant } from './constants';

export const Aside = () => {
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const isMedium = useMediaQuery(IS_MEDIUM_DEVICE);
  const dispatch = useAppDispatch();
  const { isSidebarOpen, profile } = useAppSelector(getProfileState);
  const permissions = useAppSelector(getPermissions);
  const { filters } = useAppSelector(getModalState);
  const data = useAppSelector(getModerationRequestData);
  const disabled = useAppSelector(getSubcontractorModerationFormStatus);
  const [isLogout, setIsLogout] = useState(false);

  const handleToggleOpenSidebar = (): void => {
    if (isSidebarOpen) {
      localStorage.removeItem(SIDEBAR_OPEN_LOCALSTORAGE_NAME);
    } else {
      localStorage.setItem(SIDEBAR_OPEN_LOCALSTORAGE_NAME, 'true');
    }
    dispatch(profileActions.toggleSidebar(!isSidebarOpen));
  };

  return (
    <aside
      className={classNames(
        'flex h-full flex-col justify-between pt-5 pr-5 pl-5 pb-12 transition-width duration-300 lg:h-[95vh]',
        {
          'w-full max-w-[312px]': isSidebarOpen,
          'w-full !max-w-[640px]': isSmall,
          'w-full max-w-[90px]': !isSidebarOpen,
          'h-100vh gradient-20 fixed left-0 right-0 top-0 bottom-0 z-[60] flex max-w-[375px] transition-all duration-500':
            isMedium && isSidebarOpen,
          hidden: isMedium && !isSidebarOpen,
        }
      )}
    >
      <div>
        <div
          className={classNames('flex items-center gap-2', {
            'justify-center': !isSidebarOpen,
            'justify-between': isSidebarOpen,
          })}
        >
          <AnimatePresence>
            {isSidebarOpen && (
              <motion.span
                variants={textMotionVariant}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                <Link to="/help">
                  <MProLogoWhite />
                </Link>
              </motion.span>
            )}
          </AnimatePresence>

          <button
            className={classNames(
              'flex h-9 w-9 items-center justify-center rounded-[50%] bg-base transition-transform duration-300',
              {
                'rotate-180': !isSidebarOpen,
              }
            )}
            onClick={handleToggleOpenSidebar}
          >
            {isMedium ? (
              <CloseBurgerIcon />
            ) : (
              <IconLeftArrow className="fill-black text-secondary-40" />
            )}
          </button>
        </div>
        <ul className="reset-scrollbar-styles mt-7 flex max-h-[100%] flex-col gap-2 overflow-x-hidden overflow-y-hidden">
          {navLinks.map(({ name, link, icon, guardPermission }) => {
            if (guardPermission && !permissions.includes(guardPermission)) {
              return;
            }
            return (
              <li key={link}>
                <NavLink
                  title={name}
                  to={link}
                  className={({ isActive }) =>
                    classNames(
                      'headline-medium grid items-center gap-2 rounded-lg text-base transition-colors transition-width duration-300 hover:bg-secondary-40',
                      {
                        'bg-secondary-40': isActive,
                        'grid-cols-1': !isSidebarOpen,
                        'grid-cols-aside-link': isSidebarOpen,
                      }
                    )
                  }
                >
                  <span className="block flex h-12 w-12 items-center justify-center">{icon}</span>
                  <AnimatePresence>
                    {isSidebarOpen && (
                      <motion.span
                        variants={textMotionVariant}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                      >
                        {name}
                      </motion.span>
                    )}
                  </AnimatePresence>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
      <div
        className={classNames('pt-5 transition-width duration-300', {
          'grid grid-cols-user-data items-center gap-2 border-t border-[#7EC2F8]': isSidebarOpen,
          'flex flex-col-reverse items-center gap-5': !isSidebarOpen,
        })}
      >
        <div className="flex h-10 w-10 items-center justify-center rounded-[50%] border border-[#7EC2F8] bg-secondary-50">
          <p className="text-sm text-base">{profile?.lastName?.slice(0, 1)?.toUpperCase()}</p>
          <p className="text-sm text-base">{profile?.firstName?.slice(0, 1)?.toUpperCase()}</p>
        </div>
        <AnimatePresence>
          {isSidebarOpen && (
            <motion.p
              variants={textMotionVariant}
              transition={{ duration: 0.01 }}
              initial="initial"
              animate="animate"
              exit="initial"
              className="headline-medium text-base"
            >
              {profile?.fullName}
            </motion.p>
          )}
        </AnimatePresence>
        <button
          onClick={() => {
            setIsLogout(true);
            logoutHandler();
          }}
          disabled={isLogout}
          title="Выйти"
          className={classNames({
            'border-x-0 border-b border-t-0 border-solid border-[#7EC2F8] pb-7': !isSidebarOpen,
          })}
        >
          <IconExit className="text-base" />
        </button>
      </div>
    </aside>
  );
};
