import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { ReactComponent as DropdownArrowGrayIcon } from 'assets/dropdown-arrow-gray.svg';
import classNames from 'classnames';
import { FormFilterValues } from 'components/Receipts1S';
import FilterModal from 'components/Receipts1S/FilterModal';
import { months, receiptsFilterDefaultValues } from 'components/Receipts1S/const';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useFetchData } from 'hooks/useFetchData';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { OrderFile, RecieptsByPeriodDto, RecieptsByPeriodRequest } from 'types';
import { StyledRecieptsByPeriodForm } from './styled';
import { RecieptsByPeriodFormData, RecieptsByPeriodProps } from './types';
import { toast } from 'react-toastify';

export const RecieptsByPeriod: FC<RecieptsByPeriodProps> = ({ className }) => {
  const [open, setOpen] = useState(false);
  const [toastMessages, setToastMessages] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const {
    control: formFilterControl,
    handleSubmit: formFilterHandleSubmit,
    reset: formFilterReset,
    watch: formFilterwatch,
  } = useForm<FormFilterValues>({
    defaultValues: receiptsFilterDefaultValues,
  });

  const {
    control: recieptsByPeriodFormControl,
    handleSubmit: recieptsByPeriodFormHandleSubmit,
    setValue: recieptsByPeriodFormSetValue,
    watch: recieptsByPeriodFormWatch,
  } = useForm<RecieptsByPeriodFormData>({
    defaultValues: { firstPartMonthFiles: [], secondPartMonthFiles: [] },
  });

  const [filters, setFilters] = useState<FormFilterValues>(receiptsFilterDefaultValues);

  const { data: recieptsByPeriod, refetch } = useFetchData<RecieptsByPeriodDto[]>(
    endpoints.GET_RECEIPTS_BY_PERIOD.url(filters.year.value, filters.month.value),
    endpoints.GET_RECEIPTS_BY_PERIOD.type
  );

  const addFileByPeriodHandle = useCallback(
    async (year: number, month: number, periodType: number, files: OrderFile[]) => {
      const recieptsByPeriodRequest: RecieptsByPeriodRequest = {
        month,
        year,
        periodType,
        files,
      };
      const response = await request<RecieptsByPeriodDto>(
        endpoints.ADD_FILE_BY_PERIOD.type,
        endpoints.ADD_FILE_BY_PERIOD.url(),
        {
          data: recieptsByPeriodRequest,
        }
      );
      if (periodType === 1) {
        recieptsByPeriodFormSetValue('firstPartMonthFiles', response.data.files);
        setToastMessages((prev) => [...prev, 'Загрузка прошла успешно']);
      } else {
        recieptsByPeriodFormSetValue('secondPartMonthFiles', response.data.files);
        setToastMessages((prev) => [...prev, 'Загрузка прошла успешно']);
      }
    },
    [recieptsByPeriodFormSetValue]
  );

  useEffect(() => {
    if (toastMessages.length === 1) {
      toast.success(toastMessages[0]);
      setTimeout(() => {
        setToastMessages([]);
      }, 1000);
    }
  }, [toastMessages]);

  const recieptsByPeriodFormHandleSubmitWatcher = useCallback(
    (data: RecieptsByPeriodFormData) => {
      if (data.firstPartMonthFiles?.length) {
        addFileByPeriodHandle(filters.year.value, filters.month.value, 1, data.firstPartMonthFiles);
      }

      if (data.secondPartMonthFiles?.length) {
        addFileByPeriodHandle(
          filters.year.value,
          filters.month.value,
          2,
          data.secondPartMonthFiles
        );
      }
    },
    [addFileByPeriodHandle, filters]
  );

  useEffect(() => {
    if (recieptsByPeriod) {
      recieptsByPeriodFormSetValue('firstPartMonthFiles', []);
      recieptsByPeriodFormSetValue('secondPartMonthFiles', []);

      recieptsByPeriod.forEach((period) => {
        period.periodTypeId === 1
          ? recieptsByPeriodFormSetValue('firstPartMonthFiles', period.files)
          : recieptsByPeriodFormSetValue('secondPartMonthFiles', period.files);
      });
    }
  }, [recieptsByPeriod, recieptsByPeriodFormSetValue]);

  const onShowFilters = () => {
    setOpen(true);
  };

  const onCloseFilters = () => {
    setOpen(false);
  };

  const submitHandler = () => {
    formFilterHandleSubmit((val) => {
      val.month.value === filters.month.value && val.year.value === filters.year.value
        ? refetch()
        : setFilters(val);
    })();
  };

  return (
    <div className="flex flex-col sm:h-[75vh]">
      <div>
        <div className="my-4">
          <span className="callout-normal !text-text-50">Год:</span>
          <span
            className="flex max-w-[80px] cursor-pointer items-center justify-start gap-3 text-base font-semibold !text-primary-60"
            onClick={onShowFilters}
          >
            <>
              {filters.year.value}{' '}
              <DropdownArrowGrayIcon className="h-3 w-3 -rotate-90 text-[#D1D1D6]" />
            </>
          </span>
        </div>
        <div className="mb-4">
          <span className="callout-normal !text-text-50">Месяц:</span>
          <span
            className="flex max-w-[100px] cursor-pointer items-center justify-start gap-3 text-base font-semibold !text-primary-60"
            onClick={onShowFilters}
          >
            <>
              {filters.month.value && months[+filters.month.value - 1].label}{' '}
              <DropdownArrowGrayIcon className="h-3 w-3 -rotate-90 text-[#D1D1D6]" />
            </>
          </span>
        </div>
      </div>
      <StyledRecieptsByPeriodForm
        control={recieptsByPeriodFormControl}
        onSubmit={recieptsByPeriodFormHandleSubmit(recieptsByPeriodFormHandleSubmitWatcher)}
        filters={filters}
        watch={recieptsByPeriodFormWatch}
        className={classNames({ hidden: open })}
      />
      <FilterModal
        control={formFilterControl}
        open={open}
        onSubmit={submitHandler}
        onReset={() => formFilterReset(receiptsFilterDefaultValues)}
        onClose={onCloseFilters}
        type="by-period"
        watch={formFilterwatch}
      />
    </div>
  );
};
