import { yupResolver } from '@hookform/resolvers/yup';
import { request } from 'api';
import { endpoints } from 'api/endpoints';
import {
  ArchiveIcon,
  BankIcon,
  CashIcon,
  CheckApproovedIcon,
  HistoryIcon,
  SigningIcon,
  UsersIcon,
} from 'assets/icons';
import { AxiosError } from 'axios';
import { OrganizationType } from 'enums/organizationType';
import { ESubcontractorModerationRequestStatusId } from 'enums/subcontractorModerationRequestStatus';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { checkBikAndBankSettlement } from 'pages/ModerationRequest/ExecutorModeration/steps/step3/consts';
import {
  commonErrorMessage,
  electricalErrorMessage,
  workHeightErrorMessage,
} from 'pages/ModerationRequest/consts';
import { subcontractorModerationRequestTitle } from 'pages/ModerationRequest/types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { subcontractorModerationRequestActions } from 'reduxStore/reducers/SubcontractorModerationSlice';
import { getSubcontractorModerationFormStatus } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorModerationFormStatus';
import { getSubcontractorModerationRequestData } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorModerationRequestData';
import { SubcontractorModeration } from 'reduxStore/reducers/SubcontractorModerationSlice/types';
import { SubcontractorModerationRequestService } from 'services/SubcontractorModerationRequestService';
import { SubcontractorModerationRequestWithChecklist } from 'types';
import { RadioGroup } from 'ui/RadioGroup';
import { Dropdown } from 'ui/redesign/Dropdown';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { subcontractorTypes } from '../../consts';
import { Step1Fields } from '../step1/Step1Fields';
import { getServiceAreas, isCity, isFederal, isRegion } from '../step1/consts';
import { Step2Fields } from '../step2/Step2Fields';
import {
  checkEmail,
  checkInn,
  checkPhoneNumber,
  checkSubcontractorOgrn,
  checkSubcontractorOgrnip,
} from '../step2/consts';
import { Step3Fields } from '../step3/Step3Fileds';
import { checkKPP, checkOKATO, checkOKPO } from '../step3/consts';
import { Step4Fields } from '../step4/Step4Fields';
import {
  checkСertificateElectricalSafetyScan,
  checkСertificateWorkHeightScan,
} from '../step4/consts';
import { Buttons } from './Buttons';
import { ERequestHandler, getFinalSchema, mapChecklistField } from './consts';
import { ErrorResponse, SubcontractorModerationRequestValues } from './types';
import { getSubcontractorNavigation } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorNavigation';

export const FinalStepSubcontractor = () => {
  const moderationRequestData = useAppSelector(getSubcontractorModerationRequestData);
  const disabled = useAppSelector(getSubcontractorModerationFormStatus);
  const isNavigateDisabled = useAppSelector(getSubcontractorNavigation);
  const dispatch = useAppDispatch();
  const [reRender, setReRender] = useState(false);
  const [actualSubcontractorType, setActualExecutorType] = useState<OrganizationType>(
    moderationRequestData.organizationTypeId
  );
  const disableNavigate = (value: boolean) => {
    dispatch(subcontractorModerationRequestActions.setIsNavigateDisabled(value));
  };

  const egrulFile = moderationRequestData.egrulScan ? [moderationRequestData.egrulScan] : null;
  const egripFile = moderationRequestData.egripScan ? [moderationRequestData.egripScan] : null;
  const innFile = moderationRequestData.innScan ? [moderationRequestData.innScan] : null;
  const ogrnipFile = moderationRequestData.ogrnipScan ? [moderationRequestData.ogrnipScan] : null;
  const ogrnFile = moderationRequestData.ogrnScan ? [moderationRequestData.ogrnScan] : null;
  const electricalSafetyQuantity =
    moderationRequestData.certificateElectricalSafetyQuantity !== null &&
    moderationRequestData.certificateElectricalSafetyQuantity === 0
      ? undefined
      : moderationRequestData.certificateElectricalSafetyQuantity;
  const workHeightQuantity =
    moderationRequestData.certificateWorkHeightQuantity !== null &&
    moderationRequestData.certificateWorkHeightQuantity === 0
      ? undefined
      : moderationRequestData.certificateWorkHeightQuantity;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
    getValues,
  } = useForm<SubcontractorModerationRequestValues>({
    defaultValues: {
      ...moderationRequestData,
      serviceAreaTypeId: 3,
      organizationTypeId: actualSubcontractorType,
      organizationName: moderationRequestData.organizationName,
      serviceAreas: moderationRequestData.serviceAreas,
      egrulScan: egrulFile,
      egripScan: egripFile,
      legalAddress: moderationRequestData.legalAddress,
      address: moderationRequestData.address,
      phoneNumber: moderationRequestData.phoneNumber,
      email: moderationRequestData.email,
      inn: moderationRequestData.inn,
      innScan: innFile,
      ogrn: moderationRequestData.ogrn,
      ogrnScan: ogrnFile,
      ogrnip: moderationRequestData.ogrnip,
      ogrnipScan: ogrnipFile,
      bankSettlement: moderationRequestData.bankSettlement,
      bankName: moderationRequestData.bankName,
      bik: moderationRequestData.bik,
      withVat: moderationRequestData.withVat,
      kpp: moderationRequestData.kpp,
      okato: moderationRequestData.okato,
      okpo: moderationRequestData.okpo,
      files: moderationRequestData.files,
      certificateElectricalSafetyQuantity: electricalSafetyQuantity,
      certificateElectricalSafetyScan: moderationRequestData.certificateElectricalSafetyScan,
      certificateWorkHeightQuantity: workHeightQuantity,
      certificateWorkHeightScan: moderationRequestData.certificateWorkHeightScan,
    },
    mode: 'onBlur',
    resolver: yupResolver(getFinalSchema(actualSubcontractorType)),
  });

  const actualFormValues = watch();

  const { refetch } = useQuery(
    ['refetchSubcontractorModerationRequest'],
    () => SubcontractorModerationRequestService.getCurrentModerationRequest(),
    {
      onSuccess: async (data) => {
        if (data.data.statusId === ESubcontractorModerationRequestStatusId.ElaborationRequest) {
          refetchWithCheckLists();
        } else {
          dispatch(subcontractorModerationRequestActions.setModerationRequest(data.data));
          if (
            [
              ESubcontractorModerationRequestStatusId.Refused,
              ESubcontractorModerationRequestStatusId.Registered,
              ESubcontractorModerationRequestStatusId.SendToVerification,
              ESubcontractorModerationRequestStatusId.ElaborationProvided,
              ESubcontractorModerationRequestStatusId.Signing,
            ].includes(data.data.statusId)
          ) {
            dispatch(moderationRequestActions.setIsFormDisabled(true));
          }
          if ([ESubcontractorModerationRequestStatusId.Draft].includes(data.data.statusId)) {
            dispatch(moderationRequestActions.setIsFormDisabled(false));
          }
        }
      },
      enabled: true,
    }
  );

  const { data, refetch: refetchWithCheckLists } = useQuery(
    ['getSubcontractorModerationRequestWithCheckList'],
    () => SubcontractorModerationRequestService.getCurrentModerationRequestWithCheckList(),
    {
      onSuccess: async (data) => {
        dispatch(subcontractorModerationRequestActions.setModerationRequest(data.data));
        if (
          ![
            ESubcontractorModerationRequestStatusId.Draft,
            ESubcontractorModerationRequestStatusId.ElaborationRequest,
          ].includes(data.data.statusId)
        ) {
          dispatch(subcontractorModerationRequestActions.setIsFormDisabled(true));
        }
      },
      enabled: false,
    }
  );

  const hasChecklist = (
    currentRequest: SubcontractorModeration | SubcontractorModerationRequestWithChecklist | null
  ): currentRequest is SubcontractorModerationRequestWithChecklist =>
    currentRequest?.statusId === ESubcontractorModerationRequestStatusId.ElaborationRequest &&
    'checkList' in currentRequest &&
    !!currentRequest.checkList;

  const checkFieldInChecklist = (
    currentRequest: SubcontractorModeration | SubcontractorModerationRequestWithChecklist | null,
    fieldName: string
  ) => {
    if (hasChecklist(currentRequest)) {
      const { checkList } = currentRequest;
      //Вычисляем, можно ли редактировать поля
      if (checkList[mapChecklistField[fieldName]]) {
        return !checkList[mapChecklistField[fieldName]].isEditable;
      }
      // Если поле не пришло в чек-листе, то не блокируем его
      return false;
    }
    return disabled;
  };

  // Обновление черновика заявки
  const onDraftUpdateHandler = async (requestBody: SubcontractorModeration) => {
    try {
      const response = await request<SubcontractorModeration>(
        endpoints.SUBCONTRACTOR_MODERATION_UPDATE.type,
        endpoints.SUBCONTRACTOR_MODERATION_UPDATE.url(requestBody.id),
        {
          data: requestBody,
        }
      );
      if (response.status === 200) {
        toast.success('Черновик сохранен');
        refetch();
      }
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response && axiosError.response.data.status) {
        toast.error(commonErrorMessage);
      }
      if (axiosError.response && axiosError?.response.data.errors['Files.Count']) {
        toast.error(axiosError?.response.data.errors['Files.Count'][0]);
      } else if (
        axiosError.response &&
        axiosError?.response.data.errors['CertificateElectricalSafetyQuantity']
      ) {
        toast.error(electricalErrorMessage);
      } else if (
        axiosError.response &&
        axiosError?.response.data.errors['CertificateWorkHeightQuantity']
      ) {
        toast.error(workHeightErrorMessage);
      } else {
        toast.error(commonErrorMessage);
      }
    }
  };

  // Отправка уточнения по заявке
  const onSendElaborationHandler = async (
    requestBody: SubcontractorModerationRequestWithChecklist
  ) => {
    try {
      const response = await request<SubcontractorModeration>(
        endpoints.SUBCONTRACTOR_MODERATION_REQUEST_SEND_ELABORATION.type,
        endpoints.SUBCONTRACTOR_MODERATION_REQUEST_SEND_ELABORATION.url(requestBody.id),
        {
          data: requestBody,
        }
      );
      if (response.status === 200) {
        toast.success('Уточнение отправлено');
        refetch();
      }
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response && axiosError?.response.data.errors['Files.Count']) {
        toast.error(axiosError?.response.data.errors['Files.Count'][0]);
      } else if (
        axiosError.response &&
        axiosError?.response.data.errors['CertificateElectricalSafetyQuantity']
      ) {
        toast.error(electricalErrorMessage);
      } else if (
        axiosError.response &&
        axiosError?.response.data.errors['CertificateWorkHeightQuantity']
      ) {
        toast.error(workHeightErrorMessage);
      } else {
        toast.error(commonErrorMessage);
      }
    }
  };

  // Отправка черновика на модерацию
  const onSendToModerationHandler = async (requestBody: SubcontractorModeration) => {
    try {
      const response = await request<SubcontractorModeration>(
        endpoints.SUBCONTRACTOR_MODERATION_SEND_TO_VERIFY.type,
        endpoints.SUBCONTRACTOR_MODERATION_SEND_TO_VERIFY.url(requestBody.id),
        {
          data: requestBody,
        }
      );
      if (response.status === 200) {
        toast.success('Заявка отправлена на верификацию');
        refetch();
      }
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response && axiosError?.response.data.errors['Files.Count']) {
        toast.error(axiosError?.response.data.errors['Files.Count'][0]);
      } else if (
        axiosError.response &&
        axiosError?.response.data.errors['CertificateElectricalSafetyQuantity']
      ) {
        toast.error(electricalErrorMessage);
      } else if (
        axiosError.response &&
        axiosError?.response.data.errors['CertificateWorkHeightQuantity']
      ) {
        toast.error(workHeightErrorMessage);
      } else if (axiosError.response && axiosError?.response.data.errors['Inn']) {
        toast.error('Введите валидный ИНН.');
      } else {
        toast.error(commonErrorMessage);
      }
    }
  };
  // Сохранение черновика
  const onCreateModerationHandler = async (requestBody: SubcontractorModeration) => {
    try {
      const response = await request<SubcontractorModeration>(
        endpoints.SUBCONTRACTOR_MODERATION_CREATE.type,
        endpoints.SUBCONTRACTOR_MODERATION_CREATE.url(requestBody.id),
        {
          data: requestBody,
        }
      );
      if (response.status === 200) {
        toast.success('Черновик сохранен');
        refetch();
      }
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response && axiosError?.response.data.errors['Files.Count']) {
        toast.error(axiosError?.response.data.errors['Files.Count'][0]);
      } else if (
        axiosError.response &&
        axiosError?.response.data.errors['CertificateElectricalSafetyQuantity']
      ) {
        toast.error(electricalErrorMessage);
      } else if (
        axiosError.response &&
        axiosError?.response.data.errors['CertificateWorkHeightQuantity']
      ) {
        toast.error(workHeightErrorMessage);
      } else {
        toast.error(commonErrorMessage);
      }
    }
  };

  useEffect(() => {
    const serviceAreasC = getValues('serviceAreasC') ?? [];
    const serviceAreasR = getValues('serviceAreasR') ?? [];
    const serviceAreasF = getValues('serviceAreasF') ?? [];

    if (moderationRequestData.serviceAreas?.length > 0) {
      for (let i = 0; i < moderationRequestData.serviceAreas.length; i++) {
        if (isCity(moderationRequestData.serviceAreas[i])) {
          setValue('serviceAreaTypeId', moderationRequestData.serviceAreas[i].serviceAreaTypeId);
          serviceAreasC.push({
            label: moderationRequestData.serviceAreas[i].objectFullName || '',
            value: {
              fiasId: moderationRequestData.serviceAreas[i].cityFiasId || '',
              objectFullName: moderationRequestData.serviceAreas[i].objectFullName || '',
            },
          });
          setValue('serviceAreasC', serviceAreasC);
        } else if (isRegion(moderationRequestData.serviceAreas[i])) {
          setValue('serviceAreaTypeId', moderationRequestData.serviceAreas[i].serviceAreaTypeId);
          serviceAreasR.push({
            label: moderationRequestData.serviceAreas[i].objectFullName || '',
            value: {
              fiasId: moderationRequestData.serviceAreas[i].regionFiasId || '',
              objectFullName: moderationRequestData.serviceAreas[i].objectFullName || '',
            },
          });
          setValue('serviceAreasR', serviceAreasR);
        } else if (isFederal(moderationRequestData.serviceAreas[i])) {
          setValue('serviceAreaTypeId', moderationRequestData.serviceAreas[i].serviceAreaTypeId);
          if (!serviceAreasF.includes(moderationRequestData.serviceAreas[i].federalDistrictId)) {
            serviceAreasF.push(moderationRequestData.serviceAreas[i].federalDistrictId);
            setValue('serviceAreasF', serviceAreasF);
          }
        }
      }
    }
  }, []);

  const getRequestBody = (formData: SubcontractorModerationRequestValues) => {
    const formValues = formData;
    const egrulScan =
      actualSubcontractorType === OrganizationType.Individual
        ? null
        : formData.egrulScan
        ? (formData as any).egrulScan[0]
        : null;
    const egripScan =
      actualSubcontractorType === OrganizationType.LLC
        ? null
        : formData.egripScan
        ? (formData as any).egripScan[0]
        : null;

    const innScan = formData.innScan ? (formData as any).innScan[0] : null;
    const ogrnipScan =
      actualSubcontractorType !== OrganizationType.Individual
        ? null
        : (formData as any).ogrnipScan?.length > 0
        ? (formData as any).ogrnipScan[0]
        : null;

    const ogrnScan =
      actualSubcontractorType !== OrganizationType.LLC
        ? null
        : (formData as any).ogrnScan?.length > 0
        ? (formData as any).ogrnScan[0]
        : null;

    // Формируем тело запроса из данных формы
    const temp = {
      inn: formValues.inn,
      bik: formValues.bik,
      bankSettlement: formValues.bankSettlement,
      phoneNumber: formValues.phoneNumber,
      email: formValues.email,
      withVat: formValues.withVat,
      organizationTypeId: actualSubcontractorType,
      organizationName: formValues.organizationName ? formValues.organizationName.trim() : '',
      serviceAreaTypeId: formValues.serviceAreaTypeId,
      serviceAreas: getServiceAreas(formValues),
      egrulScan: egrulScan,
      egripScan: egripScan,
      legalAddress: formValues.legalAddress ? formValues.legalAddress.trim() : '',
      address: formValues.address ? formValues.address.trim() : '',
      innScan: innScan,
      ogrn: actualSubcontractorType === OrganizationType.LLC ? formValues.ogrn : '',
      ogrnScan: ogrnScan,
      ogrnip: actualSubcontractorType === OrganizationType.Individual ? formValues.ogrnip : '',
      ogrnipScan: ogrnipScan,
      bankName: formValues.bankName ? formValues.bankName.trim() : '',
      kpp: actualSubcontractorType === OrganizationType.LLC ? formValues.kpp : null,
      okato: actualSubcontractorType === OrganizationType.LLC ? formValues.okato : null,
      okpo: actualSubcontractorType === OrganizationType.LLC ? formValues.okpo : null,
      files: formValues.files,
      contractSubcontractorProjectFiles: formValues.contractSubcontractorProjectFiles,
      certificateElectricalSafetyQuantity: formValues.certificateElectricalSafetyQuantity
        ? formValues.certificateElectricalSafetyQuantity
        : 0,
      certificateWorkHeightQuantity: formValues.certificateWorkHeightQuantity
        ? formValues.certificateWorkHeightQuantity
        : 0,
      certificateElectricalSafetyScan: formValues.certificateElectricalSafetyScan,
      certificateWorkHeightScan: formValues.certificateWorkHeightScan,
    };
    let requestBody: SubcontractorModeration;
    if (moderationRequestData.id) {
      const { id, statusId, rowVersion, contractSubcontractorProjectFiles, ownerOrganizationId } =
        moderationRequestData;
      requestBody = {
        ...temp,
        id,
        statusId,
        rowVersion,
        contractSubcontractorProjectFiles,
        ownerOrganizationId,
      };
    } else {
      // const { rowVersion } = moderationRequestData;
      requestBody = {
        ...temp,
        id: 0,
        statusId: 1,
        contractSubcontractorProjectFiles: null,
      };
    }
    return requestBody;
  };

  const submitHandler = async (requestHandler?: ERequestHandler) => {
    const requestBody = getRequestBody(actualFormValues);
    if (requestHandler === 'DRAFT' || requestHandler === 'MODERATION') {
      clearErrors();
    }
    let toastErrors: string[] = [];
    if (requestBody.serviceAreas?.length === 0 || !requestBody.serviceAreas) {
      setError('serviceAreas', {
        type: 'manual',
        message: 'Территория обслуживания должна быть заполнена',
      });
      toastErrors.push('Территория обслуживания должна быть заполнена');
    }
    const isValid = requestHandler === 'DRAFT' ? true : await trigger();

    if (requestBody.organizationName?.length === 0) {
      setError('organizationName', {
        type: 'manual',
        message: 'Наименование Организации/ИП должно быть заполнено',
      });
      toastErrors.push('Наименование Организации/ИП должно быть заполнено');
    }
    if (
      (requestBody.ogrnip || requestBody.ogrnipScan?.length) &&
      actualSubcontractorType === OrganizationType.Individual
    ) {
      toastErrors = checkSubcontractorOgrnip(
        requestBody.ogrnip,
        requestBody.ogrnipScan,
        toastErrors,
        setError
      );
    }
    if (
      (requestBody.ogrn || requestBody?.ogrnScan?.length) &&
      actualSubcontractorType === OrganizationType.LLC
    ) {
      toastErrors = checkSubcontractorOgrn(
        requestBody.ogrn,
        requestBody.ogrnScan,
        toastErrors,
        setError
      );
    }
    if (requestBody.inn || requestBody.innScan?.length) {
      toastErrors = checkInn(
        requestBody.inn,
        requestBody.innScan,
        actualSubcontractorType,
        toastErrors,
        setError
      );
    }
    if (requestBody.phoneNumber) {
      toastErrors = checkPhoneNumber(requestBody.phoneNumber, toastErrors, setError);
    }
    if (requestBody.bankSettlement || requestBody.bik) {
      toastErrors = checkBikAndBankSettlement(
        requestBody.bankSettlement,
        requestBody.bik,
        toastErrors,
        setError
      );
    }
    if (requestBody.okato) {
      toastErrors = checkOKATO(requestBody.okato, toastErrors, setError);
    }
    if (requestBody.okpo) {
      toastErrors = checkOKPO(requestBody.okpo, toastErrors, setError);
    }
    if (requestBody.kpp) {
      toastErrors = checkKPP(requestBody.kpp, toastErrors, setError);
    }
    if (requestBody.email) {
      toastErrors = checkEmail(requestBody.email, 100, toastErrors, setError);
    }
    if (
      requestBody.certificateElectricalSafetyQuantity ||
      requestBody.certificateElectricalSafetyScan?.length
    ) {
      toastErrors = checkСertificateElectricalSafetyScan(
        requestBody.certificateElectricalSafetyQuantity,
        requestBody.certificateElectricalSafetyScan,
        toastErrors,
        setError
      );
    }
    if (
      requestBody.certificateWorkHeightQuantity ||
      requestBody.certificateWorkHeightScan?.length
    ) {
      toastErrors = checkСertificateWorkHeightScan(
        requestBody.certificateWorkHeightQuantity,
        requestBody.certificateWorkHeightScan,
        toastErrors,
        setError
      );
    }

    if (toastErrors.length) {
      setReRender(!reRender);
      return;
    }

    if (isValid) {
      if (
        moderationRequestData &&
        moderationRequestData.statusId === ESubcontractorModerationRequestStatusId.Draft &&
        requestHandler === 'DRAFT'
      ) {
        // console.log(requestBody);
        onDraftUpdateHandler(requestBody);
      } else if (
        moderationRequestData &&
        moderationRequestData.statusId === ESubcontractorModerationRequestStatusId.Draft &&
        requestHandler === 'MODERATION'
      ) {
        // console.log(requestBody);
        onSendToModerationHandler(requestBody);
      } else if (
        moderationRequestData &&
        moderationRequestData.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest &&
        hasChecklist(moderationRequestData)
      ) {
        const checkList = moderationRequestData.checkList;
        const elaborationRequestBody: SubcontractorModerationRequestWithChecklist = {
          ...requestBody,
          checkList,
        };
        onSendElaborationHandler(elaborationRequestBody);
      } else {
        onCreateModerationHandler(requestBody);
      }
    }
  };

  const changeStatus = (value: number) => {
    if (actualSubcontractorType === OrganizationType.Individual) {
      setValue('inn', '');
      setValue('innScan', null);
      setValue('ogrnipScan', null);
      setValue('ogrnip', '');
    } else {
      setValue('inn', '');
      setValue('innScan', null);
      setValue('ogrnScan', null);
      setValue('ogrn', '');
    }
    setActualExecutorType(value);
  };

  useEffect(() => {
    const uniqueMessages = [];
    for (const key in errors) {
      uniqueMessages.push((errors[key as keyof typeof errors] as any).message);
    }
    const uniqueErrors = new Set(uniqueMessages);
    Array.from(uniqueErrors).forEach((message: string) => {
      toast.error(message);
    });
  }, [errors, reRender]);

  return (
    <PageWrapper
      title={
        moderationRequestData && moderationRequestData.id
          ? `Заявка №${moderationRequestData.id}`
          : `Заявка на модерацию`
      }
    >
      <div className="my-3 sm:my-0">
        <p className="callout-normal sm:body-normal !text-text-50">Статус:</p>
        <p className="body-bold sm:headline-bold mt-1 flex items-center gap-3 !text-primary-60">
          {moderationRequestData.statusId === ESubcontractorModerationRequestStatusId.Signing && (
            <SigningIcon className="h-5 w-5" />
          )}
          {moderationRequestData.statusId ===
            ESubcontractorModerationRequestStatusId.Registered && (
            <CheckApproovedIcon className="h-5 w-5" />
          )}
          {subcontractorModerationRequestTitle[moderationRequestData.statusId]
            ? subcontractorModerationRequestTitle[moderationRequestData.statusId]
            : 'Черновик'}
        </p>
      </div>
      <div className="mt-3 flex items-center">
        <CashIcon className="mr-3.5" />
        <span className="body-bold sm:title2-bold text-text-100">Налоговый статус</span>
      </div>
      <div className="mt-4 flex flex-col gap-2 sm:gap-6">
        <div className="sm:max-w-[450px]">
          <RadioGroup
            data={subcontractorTypes}
            value={actualSubcontractorType}
            onChange={(value: number) => changeStatus(value)}
            disabled={
              moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
                ? true
                : disabled
            }
          />
        </div>
        <form className="flex min-h-[55vh] flex-col sm:gap-6">
          <Dropdown title="Данные о компании" Icon={UsersIcon}>
            <Step1Fields
              clearErrors={clearErrors}
              control={control}
              isFormDisabled={disabled}
              errors={errors}
              setValue={setValue}
              watch={watch}
              checkFieldInChecklist={checkFieldInChecklist}
              moderationRequestData={moderationRequestData}
              organizationTypeId={actualSubcontractorType}
              disableNavigate={disableNavigate}
            />
          </Dropdown>
          <Dropdown title="Основные реквизиты" Icon={HistoryIcon}>
            <Step2Fields
              clearErrors={clearErrors}
              control={control}
              errors={errors}
              isFormDisabled={disabled}
              checkFieldInChecklist={checkFieldInChecklist}
              moderationRequestData={moderationRequestData}
              organizationTypeId={actualSubcontractorType}
              disableNavigate={disableNavigate}
            />
          </Dropdown>
          <Dropdown title="Банковские реквизиты" Icon={BankIcon}>
            <Step3Fields
              control={control}
              errors={errors}
              isFormDisabled={disabled}
              clearErrors={clearErrors}
              organizationTypeId={actualSubcontractorType}
              checkFieldInChecklist={checkFieldInChecklist}
              moderationRequestData={moderationRequestData}
              disableNavigate={disableNavigate}
            />
          </Dropdown>
          <Dropdown title="Документы" Icon={ArchiveIcon}>
            <Step4Fields
              control={control}
              isFormDisabled={disabled}
              errors={errors}
              clearErrors={clearErrors}
              checkFieldInChecklist={checkFieldInChecklist}
              moderationRequestData={moderationRequestData}
              watch={watch}
              disableNavigate={disableNavigate}
            />
          </Dropdown>
        </form>
        <div className="mb-4">
          <Buttons
            status={moderationRequestData.statusId}
            submitHandler={submitHandler}
            id={moderationRequestData.id}
            isNavigateDisabled={isNavigateDisabled}
          />
        </div>
      </div>
    </PageWrapper>
  );
};
