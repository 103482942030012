import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useFirebase } from 'hooks/useFirebase';
import { User as oidcUser } from 'oidc-client';
import { AuthProvider } from 'oidc-react';
import { AuthProviderProps } from 'oidc-react/build/src/AuthContextInterface';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useNavigate, useRoutes } from 'react-router-dom';
import { profileActions } from 'reduxStore/reducers/ProfileSlice';
import { createRouter } from 'routes';
import { getProfile } from 'services';
import { useOrderRoute } from './hooks/useOrderRoute';
import { oidcSettings } from './utils/settings';
import { ProfileService } from 'services/ProfileService';
import { useNavigationHistory } from 'navigationProvider';
import { useAppSelector } from 'hooks/useAppSelector';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import { useEffect, useState } from 'react';

function App() {
  const { clearHistory } = useNavigationHistory();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const executorType = useAppSelector(getExecutorType);
  const routing = useRoutes(createRouter(executorType));

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnReconnect: 'always',
        refetchOnMount: 'always',
        staleTime: 1_000,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  });

  const { refetch } = useQuery(['getProfile'], () => ProfileService.getProfile(), {
    onSuccess: async (data) => {
      dispatch(profileActions.setProfile(data.data));
    },
  });

  const [isPermissionsLoaded, setIsPermissionsLoaded] = useState(false);
  const { mainUrl } = useOrderRoute();

  const onSignIn = (userData: oidcUser | null) => {
    userData && sessionStorage.setItem('token', userData.access_token);
    clearHistory();
    getProfile()
      .then((response) => {
        if (response.status === 200) {
          dispatch(profileActions.setProfile(response.data));
        }
      })
      .then(() => {
        return getPermissions();
      })
      .then(() => {
        setIsPermissionsLoaded(true);
      })
      .then(() => {
        navigate(mainUrl);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSignOut = () => {
    sessionStorage.clear();
  };

  // Получение разрешений пользователя
  const getPermissions = async () => {
    const response = await request<number[]>(
      endpoints.GET_USER_PERMISSIONS.type,
      endpoints.GET_USER_PERMISSIONS.url()
    );
    if (response.status === 200) {
      dispatch(profileActions.setPermissions(response.data));
    }
  };

  useEffect(() => {
    isPermissionsLoaded && navigate(mainUrl);
  }, [isPermissionsLoaded, mainUrl]);

  const settings: AuthProviderProps = {
    ...oidcSettings,
    onSignIn,
    onSignOut,
  };

  useFirebase();

  return (
    <AuthProvider {...settings}>
      <QueryClientProvider client={queryClient}>{routing}</QueryClientProvider>
    </AuthProvider>
  );
}
export default App;
