import { ESubcontractorCheckListType } from 'enums/subcontractorCheckListType';
import { UseFormSetError } from 'react-hook-form';
import * as yup from 'yup';
import { ServiceArea } from './types';
import { OrganizationType } from 'enums/organizationType';

export enum ERequestHandler {
  DRAFT = 'DRAFT',
  MODERATION = 'MODERATION',
  SECONDARY = 'SECONDARY',
}

export const mapChecklistField: { [p: string]: keyof typeof ESubcontractorCheckListType } = {
  organizationName: 'OrganizationName',
  egrulScan: 'Egrul',
  egripScan: 'Egrip',
  serviceAreaTypeId: 'ServiceAreaType',
  legalAddress: 'LegalAddress',
  address: 'Address',
  phoneNumber: 'PhoneNumber',
  email: 'Email',
  inn: 'Inn',
  innScan: 'InnScan',
  ogrn: 'Ogrn',
  ogrnScan: 'OgrnScan',
  ogrnip: 'Ogrnip',
  ogrnipScan: 'OgrnipScan',
  bankSettlement: 'BankSattelment',
  bankName: 'BankName',
  bik: 'Bik',
  withVat: 'WithVAT',
  kpp: 'Kpp',
  okato: 'Okato',
  okpo: 'Okpo',
  files: 'OtherSubcontractorDocs',
  certificateElectricalSafetyScan: 'CertificateElectricalSafetyScan',
  certificateWorkHeightScan: 'CertificateWorkHeightScan',
};

export const EmailValidator = (value: any): boolean => {
  if (value?.length === 0) {
    return true;
  }

  let isValid = false;

  if (value?.length <= 100) {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    isValid = emailRegex.test(value);

    if (isValid) {
      const arr = value?.split('@');
      isValid = arr.length == 2 && arr[1].includes('.');
    }
  }

  return isValid;
};

export const subcontractorIndividualSchema = yup.object().shape({
  organizationName: yup
    .string()
    .typeError('Наименование Организации/ИП должно быть заполнено')
    .required('Наименование Организации/ИП должно быть заполнено'),
  egripScan: yup.array().typeError('Необходимо приложить файл').min(1, 'Необходимо приложить файл'),
  legalAddress: yup
    .string()
    .max(1000, 'Поле "Юридический адрес" должно быть заполнено и не превышать 1000 символов')
    .required('Поле "Юридический адрес" должно быть заполнено и не превышать 1000 символов'),
  address: yup
    .string()
    .max(1000, 'Поле "Фактический адрес" должно быть заполнено и не превышать 1000 символов')
    .required('Поле "Фактический адрес" должно быть заполнено и не превышать 1000 символов'),
  phoneNumber: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .required('Телефон не соответствует шаблону')
    .min(16, 'Телефон не соответствует шаблону'),
  email: yup
    .string()
    .required('Электронная почта не соответствует шаблону')
    .test('EmailValidator', 'Электронная почта не соответствует шаблону', EmailValidator),
  inn: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .typeError('Введите валидный ИНН. ИНН должен состоять из 12 цифр')
    .min(12, 'Введите валидный ИНН. ИНН должен состоять из 12 цифр'),
  ogrnip: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .typeError('Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр')
    .min(15, 'Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр'),
  innScan: yup.array().typeError('Необходимо приложить файл').min(1, 'Необходимо приложить файл'),
  ogrnipScan: yup
    .array()
    .typeError('Необходимо приложить файл')
    .min(1, 'Необходимо приложить файл'),
  bankSettlement: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .min(20, 'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр'),
  bankName: yup
    .string()
    .transform((value) => value.trim())
    .required('Поле "Полное наименование банка" должно быть заполнено и не превышать 150 символов')
    .max(150, 'Поле "Полное наименование банка" должно быть заполнено и не превышать 150 символов'),
  bik: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .min(9, 'Неверный формат БИК. БИК должен состоять из 9 цифр'),
  certificateElectricalSafetyScan: yup.array().when('certificateElectricalSafetyQuantity', {
    is: (quantity: any) => quantity !== undefined && quantity !== '',
    then: yup
      .array()
      .typeError('Необходимо приложить 1 или более файлов')
      .min(1, 'Необходимо приложить 1 или более файлов')
      .max(5, 'Необходимо приложить не более 5 файлов'),
    otherwise: yup.array().notRequired(),
  }),
  certificateWorkHeightScan: yup.array().when('certificateWorkHeightQuantity', {
    is: (quantity: any) => quantity !== undefined && quantity !== '',
    then: yup
      .array()
      .typeError('Необходимо приложить 1 или более файлов')
      .min(1, 'Необходимо приложить 1 или более файлов')
      .max(5, 'Необходимо приложить не более 5 файлов'),
    otherwise: yup.array().notRequired(),
  }),
});

export const subcontractorLLCSchema = yup.object().shape({
  organizationName: yup
    .string()
    .typeError('Наименование Организации/ИП должно быть заполнено')
    .required('Наименование Организации/ИП должно быть заполнено'),
  egrulScan: yup.array().typeError('Необходимо приложить файл').min(1, 'Необходимо приложить файл'),
  legalAddress: yup
    .string()
    .max(1000, 'Поле "Юридический адрес" должно быть заполнено и не превышать 1000 символов')
    .required('Поле "Юридический адрес" должно быть заполнено и не превышать 1000 символов'),
  address: yup
    .string()
    .max(1000, 'Поле "Фактический адрес" должно быть заполнено и не превышать 1000 символов')
    .required('Поле "Фактический адрес" должно быть заполнено и не превышать 1000 символов'),
  phoneNumber: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .required('Телефон не соответствует шаблону')
    .min(16, 'Телефон не соответствует шаблону'),
  email: yup
    .string()
    .required('Электронная почта не соответствует шаблону')
    .test('EmailValidator', 'Электронная почта не соответствует шаблону', EmailValidator),
  inn: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .typeError('Введите валидный ИНН. ИНН должен состоять из 10 цифр')
    .min(10, 'Введите валидный ИНН. ИНН должен состоять из 10 цифр'),
  ogrn: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .typeError('Введите валидный ОГРН. ОГРН должен состоять из 13 цифр')
    .min(13, 'Введите валидный ОГРН. ОГРН должен состоять из 13 цифр'),
  kpp: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .typeError('Неверный формат КПП. КПП должен состоять из 9 цифр')
    .min(9, 'Неверный формат КПП. КПП должен состоять из 9 цифр'),
  okato: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .typeError('Неверный формат ОКАТО. ОКАТО должен состоять из 11 цифр')
    .min(11, 'Неверный формат ОКАТО. ОКАТО должен состоять из 11 цифр'),
  okpo: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .typeError('Неверный формат ОКПО. ОКПО должен состоять из 8 цифр')
    .min(8, 'Неверный формат ОКПО. ОКПО должен состоять из 8 цифр'),
  innScan: yup.array().typeError('Необходимо приложить файл').min(1, 'Необходимо приложить файл'),
  ogrnScan: yup.array().typeError('Необходимо приложить файл').min(1, 'Необходимо приложить файл'),
  bankSettlement: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .min(20, 'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр'),
  bankName: yup
    .string()
    .transform((value) => value.trim())
    .required('Поле "Полное наименование банка" должно быть заполнено и не превышать 150 символов')
    .max(150, 'Поле "Полное наименование банка" должно быть заполнено и не превышать 150 символов'),
  bik: yup
    .string()
    .transform((value) => value !== null && value.replace(/_/g, ''))
    .min(9, 'Неверный формат БИК. БИК должен состоять из 9 цифр'),
  certificateElectricalSafetyScan: yup.array().when('certificateElectricalSafetyQuantity', {
    is: (quantity: any) => quantity !== undefined && quantity !== '',
    then: yup
      .array()
      .typeError('Необходимо приложить 1 или более файлов')
      .min(1, 'Необходимо приложить 1 или более файлов')
      .max(5, 'Необходимо приложить не более 5 файлов'),
    otherwise: yup.array().notRequired(),
  }),
  certificateWorkHeightScan: yup.array().when('certificateWorkHeightQuantity', {
    is: (quantity: any) => quantity !== undefined && quantity !== '',
    then: yup
      .array()
      .typeError('Необходимо приложить 1 или более файлов')
      .min(1, 'Необходимо приложить 1 или более файлов')
      .max(5, 'Необходимо приложить не более 5 файлов'),
    otherwise: yup.array().notRequired(),
  }),
});

export const getFinalSchema = (actualSubcontractorType: OrganizationType) => {
  const finalSchema =
    actualSubcontractorType === OrganizationType.Individual
      ? subcontractorIndividualSchema
      : subcontractorLLCSchema;
  return finalSchema;
};
