import { FieldCheckbox } from 'components/redesign/FieldCheckbox';
import { SettlementsSelect } from 'components/redesign/SettlementsSelect';
import { SpecSelect } from 'components/redesign/SpecSelect';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC, useState } from 'react';
import { MultiValue } from 'react-select';
import { ExtendedSuggestion, SettlementShort } from 'types';
import { OptionType } from 'ui/redesign/Select/types';
import { downloadStaticFile, handleFileDownload } from 'utils/helpers';
import { StaticFiles } from 'utils/settings';
import { specsData } from './consts';
import { Step4Props } from './types';

export const Step4Fields: FC<Step4Props> = ({
  control,
  watch,
  errors,
  setValue,
  isFormDisabled,
  parentId,
  clearErrors,
}) => {
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const watchServiceCities = watch('serviceCities');
  const onChangeHandler = async (
    e: MultiValue<OptionType<SettlementShort | ExtendedSuggestion>>
  ) => {
    if (watchServiceCities && e.length < watchServiceCities.length) {
      setCanUpdate(true);
    }
  };

  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      {parentId === 0 && (
        <SettlementsSelect
          isMulti
          control={control}
          label="Города обслуживания"
          name="serviceCities"
          onChange={onChangeHandler}
          setValue={setValue}
          isFormDisabled={isFormDisabled}
          variant="cities"
          errorMessage={errors?.serviceCities?.message}
          clearErrors={clearErrors}
        />
      )}
      {parentId === 0 && (
        <SpecSelect
          name="executorSpecializations"
          control={control}
          label="Область специализации"
          options={specsData}
          watch={watch}
          disabled={isFormDisabled}
          errorMessage={errors?.executorSpecializations?.message}
          clearErrors={clearErrors}
        />
      )}
      <div>
        {parentId !== 0 && (
          <SpecSelect
            name="executorSpecializations"
            control={control}
            label="Область специализации"
            options={specsData}
            watch={watch}
            disabled={isFormDisabled}
            errorMessage={errors?.executorSpecializations?.message}
            clearErrors={clearErrors}
          />
        )}
        <div className="mt-6 mb-5 flex">
          <FieldCheckbox
            name="acceptsOffer"
            control={control}
            errorMessage={(errors as any)?.acceptsOffer?.message}
            title="Я принимаю оферту"
            disabled={isFormDisabled}
            clearErrors={clearErrors}
          />
        </div>
        <p>
          <span
            className="footnote-medium sm:callout-normal cursor-pointer !text-primary-60"
            onClick={() =>
              handleFileDownload(downloadStaticFile(StaticFiles.SelfEmployed_Contract_Offer))
            }
          >
            Ссылка на скачивание оферты
          </span>
        </p>
        {/* <p>
          <span
            className="footnote-medium sm:callout-normal cursor-pointer !text-primary-60"
            onClick={() => handleFileDownload(downloadStaticFile(StaticFiles.User_Agreement))}
          >
            Ссылка на скачивание пользовательского соглашения
          </span>
        </p> */}
      </div>
    </div>
  );
};
