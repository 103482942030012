import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { ReactComponent as MoreIcon } from 'assets/chat-more.svg';
import { ReactComponent as CheckAll } from 'assets/check-all.svg';
import { ReactComponent as DropdownArrowIcon } from 'assets/dropdown-arrow.svg';
import { ChatIcon, EmptyChatIcon, FileDownloadIcon, UploadChatFileIcon } from 'assets/icons';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ChatEntityType } from 'enums/chatEntityType';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useFetchData } from 'hooks/useFetchData';
import useMediaQuery from 'hooks/useMediaQuery';
import { Fragment, memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { getProfileID } from 'reduxStore/reducers/ProfileSlice/selectors/getProfileID';
import { getTimezone } from 'reduxStore/reducers/ProfileSlice/selectors/getTimezone';
import { ChatMembershipV2, ChatMessageV2, ChatUser, Order, OrderFile } from 'types';
import { DropDown } from 'ui/DropDown';
import UploadInput from 'ui/UploadInput';
import { editChatRuLocale, getDateWithTimezone } from 'utils/helpers';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { EMPTY_MESSAGE_TEXT_ERROR, EMPTY_MESSAGE_TOAST_ERROR } from './consts';
import { ChatMessageWithFilesV2, ChatProps, ChatsWithFilesV2 } from './types';

const Chat = ({ orderId, entityType, isMessageCanSent = true, customId }: ChatProps) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<{
    chats: ChatsWithFilesV2 | null;
    messages: ChatMessageWithFilesV2[];
    members: ChatMembershipV2[];
    lastMessage: ChatMessageV2 | null;
  }>({
    chats: null,
    messages: [],
    members: [],
    lastMessage: null,
  });
  const [notViewedMessages, setNotViewedMessages] = useState<number>(0);
  const textAreaValue = useRef('');
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const timeZone = useAppSelector(getTimezone);
  const currentUserId = useAppSelector(getProfileID);
  const [uploadedFiles, setUploadedFiles] = useState<OrderFile[] | undefined>(undefined);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  const { data: order } = useFetchData<Order>(
    endpoints.GET_ORDER_DETAILS.url(orderId),
    endpoints.GET_ORDER_DETAILS.type,
    {},
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === 403) {
          navigate(-1);
        }
      },
    }
  );

  const sendChatMessage = async () => {
    if (!textAreaValue.current.trim().length && uploadedFiles) {
      toast.error(EMPTY_MESSAGE_TOAST_ERROR);
      return;
    }
    if (!textAreaValue.current.trim().length) {
      return;
    }
    const messageCopy = textAreaValue.current.trim();
    const reqBody = {
      content: textAreaValue.current.trim(),
      files: uploadedFiles,
    };
    textAreaValue.current = '';
    const response = await request<ChatsWithFilesV2>(
      endpoints.CHAT_ORDER_SEND_MESSAGE.type,
      endpoints.CHAT_ORDER_SEND_MESSAGE.url(orderId),
      { data: reqBody }
    );
    if (response.status === 200) {
      setAll(response.data);
      setUploadedFiles(undefined);
    } else {
      textAreaValue.current = messageCopy;
    }
  };

  // Метод для пометки сообщения "прочитанным"
  const chatViewMessage = async (orderId: number, messageId: number) => {
    const viewMessageResponse = await request<ChatsWithFilesV2>(
      endpoints.CHAT_ORDER_VIEW_MESSAGE.type,
      endpoints.CHAT_ORDER_VIEW_MESSAGE.url(orderId),
      {
        data: { messageId },
      }
    );
    if (viewMessageResponse.status === 200) {
      setAll(viewMessageResponse.data);
    }
  };

  useEffect(() => {
    request<ChatsWithFilesV2>(
      endpoints.CHAT_ORDER_GET.type,
      endpoints.CHAT_ORDER_GET.url(orderId)
    ).then((chatData) => {
      if (chatData.status === 200) {
        setAll(chatData.data);
      }
    });
  }, [orderId]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [open, state.messages, uploadedFiles]);

  useEffect(() => {
    const { lastMessage } = state;
    let enabled = true;
    if (notViewedMessages > 0 && enabled) {
      lastMessage && chatViewMessage(orderId, lastMessage.id);
      enabled = false;
    }
  }, [open]);

  useEffect(() => {
    if (state.chats && currentUserId) {
      const index = state.members.findIndex((member) => member.userId === +currentUserId);
      if (index > -1) {
        const chatMember = state.members[index];
        setNotViewedMessages(chatMember.notViewedMessages);
      } else {
        setNotViewedMessages(0);
      }
    }
  }, [state.chats, state.members, currentUserId]);

  const UserAvatar = ({ user, showAvatar }: { user: ChatUser | null; showAvatar: boolean }) => {
    if (!user || !showAvatar) {
      return null;
    }
    const initials = user.lastName.charAt(0) + user.firstName.charAt(0);
    return (
      <span className="self-start rounded-full bg-primary-60 px-[10px] py-2 text-base text-white">
        {initials.toUpperCase()}
      </span>
    );
  };

  const Message = ({
    userId,
    content,
    created,
    files,
  }: {
    userId: number;
    content: string;
    created: string;
    files?: OrderFile[];
  }) => {
    const { members } = state;
    const messageClasses = {
      currentUser: {
        wrapper: 'justify-end mr-1',
        bg: 'bg-primary-60 rounded-t-lg rounded-bl-lg',
        time: 'justify-end',
        text: '!text-white',
      },
      anotherUser: {
        wrapper: 'gap-2',
        bg: 'bg-gray-light rounded-t-lg rounded-br-lg',
        time: 'justify-start',
        text: '!text-text-100',
      },
      systemUser: {
        wrapper: 'justify-center',
        bg: 'bg-primary-60 rounded-lg',
        time: 'justify-end',
        text: '!text-white',
      },
    };
    const index = members.findIndex((member) => member.userId === userId);
    const chatMember = members[index];
    const user = chatMember?.user;
    const isCurrentUserMessage = !!userId && !!currentUserId && +currentUserId === userId;
    const showAvatar = !isCurrentUserMessage;

    const bg = isCurrentUserMessage ? messageClasses.currentUser.bg : messageClasses.anotherUser.bg;
    const wrapper = isCurrentUserMessage
      ? messageClasses.currentUser.wrapper
      : messageClasses.anotherUser.wrapper;
    const time = isCurrentUserMessage
      ? messageClasses.currentUser.time
      : messageClasses.anotherUser.time;
    const text = isCurrentUserMessage
      ? messageClasses.currentUser.text
      : messageClasses.anotherUser.text;
    return (
      <>
        <div className={`message flex ${wrapper}`}>
          <UserAvatar user={user} showAvatar={showAvatar} />
          <div className={`p-2 ${bg} flex w-72 flex-col`}>
            <p className={`leading-cz mb-1 flex-1 break-all ${text} `}>
              {user && entityType === ChatEntityType.ModerationRequestChat && (
                <span className="mb-1 block">{user.firstName}</span>
              )}
              {content
                .trim()
                .split('\n')
                .map((str, index) => (
                  <Fragment key={index}>
                    {str}
                    <br />
                  </Fragment>
                ))}
              {files &&
                files.map((file) => {
                  return (
                    <div
                      className="flex items-start justify-between gap-2 py-2"
                      key={file.downloadFileIdHash}
                    >
                      <div className="flex items-center gap-2">
                        <UploadChatFileIcon className="flex-shrink-0" />
                        <div>
                          <p className={`body-normal mb-1 ${text}`}>{file.fileName}</p>
                          <p className="callout-paragraphs-normal">
                            {file.fileName.split('.')[1].toUpperCase()}
                          </p>
                        </div>
                      </div>
                      <div>
                        <DropDown
                          buttonMode={false}
                          button={<MoreIcon className="ml-1 mt-2 cursor-pointer" />}
                          menuItems={[]}
                          file={file}
                        />
                      </div>
                    </div>
                  );
                })}
            </p>
            <p>
              <span
                className={`flex ${time} leading-cz callout-paragraphs-normal items-end gap-0.5 ${text}`}
              >
                {getDateWithTimezone(created, timeZone, 'HH:mm')}
                {isCurrentUserMessage && <CheckAll />}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  };

  function setAll(entity: ChatsWithFilesV2 | null) {
    if (!entity) {
      setState({
        chats: null,
        messages: [],
        members: [],
        lastMessage: null,
      });
    } else {
      setState({
        chats: entity,
        messages: entity.messages,
        members: entity.memberships,
        lastMessage: entity.messages[entity.messages.length - 1],
      });
    }
  }

  const OpenedChat = () => {
    let lastDate = '';
    const { chats } = state;
    const [mobileChats, setMobileChats] = useState(chats);
    const mobileTextArea = useRef<HTMLTextAreaElement>(null);
    const [mobileUploadedFiles, setMobileUploadedFiles] = useState<OrderFile[] | undefined>(
      undefined
    );
    const [showMobileErrorMessage, setShowMobileErrorMessage] = useState<string>('');

    const sendMChatMessage = async () => {
      if (mobileTextArea.current) {
        if (mobileTextArea.current.value.trim().length === 0 && uploadedFiles) {
          setShowMobileErrorMessage(EMPTY_MESSAGE_TEXT_ERROR);
          return;
        }
        if (mobileTextArea.current.value.trim().length === 0) {
          setShowMobileErrorMessage(EMPTY_MESSAGE_TEXT_ERROR);
          return;
        }
      }
      const messageCopy = mobileTextArea.current;
      let reqBody = {};
      if (mobileTextArea.current) {
        reqBody = {
          content: mobileTextArea.current.value.trim(),
          files: mobileUploadedFiles,
        };
      }
      if (mobileTextArea.current) {
        mobileTextArea.current.value = '';
      }
      const response = await request<ChatsWithFilesV2>(
        endpoints.CHAT_ORDER_SEND_MESSAGE.type,
        endpoints.CHAT_ORDER_SEND_MESSAGE.url(orderId),
        { data: reqBody }
      );
      if (response.status === 200) {
        setAll(response.data);
        setMobileUploadedFiles(undefined);
        setMobileChats(response.data);
      } else {
        if (mobileTextArea.current && messageCopy) {
          mobileTextArea.current.value = messageCopy.value;
        }
      }
    };

    const ruLocale = editChatRuLocale();

    return (
      <div className="relative h-auto w-screen max-w-[640px] self-end rounded-t-xl bg-white sm:w-full sm:px-4 sm:shadow-chatPanel md:m-0">
        <>
          {currentUserId ? (
            <>
              {!isSmall && (
                <div className="absolute -left-9 flex h-8 w-8 items-center justify-center rounded-2xl border text-text-50">
                  <DropdownArrowIcon
                    className="h-4 w-4 cursor-pointer text-text-50"
                    onClick={() => setOpen(false)}
                  />
                </div>
              )}
              {!isSmall && (
                <div
                  className="title2-medium sm:headline-bold ml-4 mb-2 mt-12 flex cursor-pointer items-center gap-2 py-2 px-[12px] text-text-100 sm:mx-0 sm:mt-0 sm:ml-0"
                  onClick={() => setOpen(false)}
                >
                  <p>{customId ? `Чат по заявке № ${customId}` : 'Чат по заявке'}</p>
                </div>
              )}
              {isSmall ? (
                <>
                  {mobileChats &&
                  mobileChats.messages.length > 0 &&
                  mobileChats.memberships.length > 0 ? (
                    <div className="mt-4 flex h-auto min-h-chatMobile w-screen flex-col overflow-x-hidden bg-white px-4 pb-4 sm:mt-0 sm:h-96 sm:min-h-full sm:w-full sm:px-2 sm:pt-2 sm:pb-4">
                      <div
                        className={classNames('flex flex-col gap-2 overflow-x-auto sm:mb-0', {
                          'mb-24': uploadedFiles?.length,
                        })}
                        id="message-container"
                      >
                        {mobileChats.messages.map(({ created, userId, content, files }) => {
                          const date = format(new Date(created), 'dd.MM.yyyy');
                          let showDate;
                          if (!lastDate || lastDate !== date) {
                            lastDate = date;
                            showDate = true;
                          } else {
                            showDate = false;
                          }
                          return (
                            <div key={created}>
                              {showDate && (
                                <div className="leading-cz relative my-4 text-xs">
                                  <span className="absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 rounded-3xl bg-light-30 py-1 px-3 text-text-100">
                                    {format(new Date(created), 'd MMMM, yyyy', {
                                      locale: ruLocale,
                                    })}
                                  </span>
                                  <hr className="text-white" />
                                </div>
                              )}
                              <Message
                                userId={userId}
                                content={content}
                                created={created}
                                files={files}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="h-auto min-h-[80vh] overflow-x-hidden px-2 pt-2 pb-4 sm:h-96 sm:min-h-full">
                      <div
                        className="flex min-h-[75vh] flex-col items-center justify-center sm:min-h-full"
                        id="message-container"
                      >
                        <EmptyChatIcon />
                        <h3 className="headline-bold text-text-100">Привет, это - чат!</h3>
                      </div>
                    </div>
                  )}
                  {isMessageCanSent && (
                    <div className={classNames('relative ml-6 flex flex-col bg-white sm:ml-0', {})}>
                      {mobileUploadedFiles &&
                      showMobileErrorMessage &&
                      mobileUploadedFiles.length > 0 ? (
                        <span className="mb-3 text-danger">{showMobileErrorMessage}</span>
                      ) : (
                        <span className="-mb-1 text-danger">{showMobileErrorMessage}</span>
                      )}
                      <UploadInput
                        name={'name'}
                        fileBusinessTypeId={6}
                        onChange={setMobileUploadedFiles}
                        label={'label'}
                        maxFileSize={10}
                        value={mobileUploadedFiles ? mobileUploadedFiles : undefined}
                        isChat={true}
                      />
                      <textarea
                        className="leading-cz callout-normal my-1 mx-10 resize-none overflow-auto rounded-xl border-t border-gray bg-light-30 p-3 pr-10 outline-none sm:w-4/5"
                        autoFocus
                        ref={mobileTextArea}
                        rows={1}
                        placeholder="Сообщение"
                        onChange={(e) => {
                          setShowMobileErrorMessage('');
                          if (mobileTextArea.current) {
                            mobileTextArea.current.value = e.target.value;
                          }
                        }}
                      />
                      <button
                        onClick={sendMChatMessage}
                        className="cursor-pointe absolute right-3 bottom-4"
                        type="button"
                      >
                        <FileDownloadIcon className="rotate-180 text-primary-60" />
                      </button>
                    </div>
                  )}
                  <div className="lastMessage" ref={lastMessageRef} />
                </>
              ) : (
                // DESKTOP
                <>
                  {chats && chats.messages.length > 0 && chats.memberships.length > 0 ? (
                    <div className="flex max-h-96 flex-col overflow-x-hidden bg-white px-4 pb-4 sm:px-2 sm:pt-2 sm:pb-4">
                      <div
                        className={classNames('flex flex-col gap-2 overflow-x-auto sm:mb-0', {
                          'mb-24': uploadedFiles?.length,
                        })}
                        id="message-container"
                      >
                        {chats.messages.map(({ created, userId, content, files }) => {
                          const date = format(new Date(created), 'dd.MM.yyyy');
                          let showDate;
                          if (!lastDate || lastDate !== date) {
                            lastDate = date;
                            showDate = true;
                          } else {
                            showDate = false;
                          }
                          return (
                            <div key={created}>
                              {showDate && (
                                <div className="leading-cz relative my-4 text-xs">
                                  <span className="absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 rounded-3xl bg-light-30 py-1 px-3 text-text-100">
                                    {format(new Date(created), 'd MMMM, yyyy', {
                                      locale: ruLocale,
                                    })}
                                  </span>
                                  <hr className="text-white" />
                                </div>
                              )}
                              <Message
                                userId={userId}
                                content={content}
                                created={created}
                                files={files}
                              />
                            </div>
                          );
                        })}
                        <div className="lastMessage" ref={lastMessageRef} />
                      </div>
                    </div>
                  ) : (
                    <div className="h-auto min-h-[80vh] overflow-x-hidden px-2 pt-2 pb-4 sm:h-96 sm:min-h-full">
                      <div
                        className="flex min-h-[75vh] flex-col items-center justify-center sm:min-h-full"
                        id="message-container"
                      >
                        <EmptyChatIcon />
                        <h3 className="headline-bold text-text-100">Привет, это - чат!</h3>
                      </div>
                    </div>
                  )}
                  {isMessageCanSent && (
                    <div className={classNames('relative ml-6 flex flex-col bg-white sm:ml-0', {})}>
                      <UploadInput
                        name={'name'}
                        fileBusinessTypeId={6}
                        onChange={setUploadedFiles}
                        label={'label'}
                        maxFileSize={10}
                        value={uploadedFiles ? uploadedFiles : undefined}
                        isChat={true}
                      />
                      <textarea
                        className="leading-cz callout-normal my-1 mx-10 resize-none overflow-auto rounded-xl border-t border-gray bg-light-30 p-3 pr-10 outline-none sm:w-4/5"
                        autoFocus
                        rows={1}
                        placeholder="Введите сообщение"
                        onChange={(e) => (textAreaValue.current = e.target.value)}
                      />
                      <button
                        onClick={sendChatMessage}
                        className="cursor-pointe absolute right-0 bottom-4"
                        type="button"
                      >
                        <FileDownloadIcon className="rotate-180 text-primary-60" />
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            ''
          )}
        </>
      </div>
    );
  };

  const openChatHandler = () => {
    dispatch(
      modalActions.handleOpenModal({
        content: <OpenedChat />,
        options: {
          title: customId ? `Чат по заявке №${customId}` : `Чат по заявке`,
          withCloseButton: true,
          withBackButton: false,
          contentClassName: 'overflow-x-hidden',
        },
      })
    );
  };

  if (!open) {
    if (order?.status === 4 || order?.status === 1) {
      return null;
    }
    if (state.chats) {
      return (
        <SmallChatPanel
          message={notViewedMessages}
          onClick={isSmall ? openChatHandler : () => setOpen(true)}
        />
      );
    } else {
      return <SmallChatPanel onClick={isSmall ? openChatHandler : () => setOpen(true)} />;
    }
  } else {
    return <OpenedChat />;
  }
};

const SmallChatPanel = ({
  onClick,
  message = null,
}: {
  onClick: () => void;
  message?: number | null;
}) => {
  return (
    <div
      className="w-fit cursor-pointer self-end rounded-tl-[20px] py-3 pl-3 pr-2 md:rounded-bl-[20px]"
      onClick={onClick}
    >
      <div className="flex items-center gap-2 text-white">
        {typeof message === 'number' ? (
          <>
            <ChatIcon className="relative text-primary-60 hover:text-primary-80 active:text-primary-100" />
            {message && (
              <span className="absolute right-3 top-5 rounded-xl bg-danger px-2 font-roboto text-[10px] leading-4">
                +{message}
              </span>
            )}
          </>
        ) : (
          <ChatIcon className="text-primary-60 hover:text-primary-80 active:text-primary-100" />
        )}
      </div>
    </div>
  );
};

export const ChatOrder = memo(Chat);
