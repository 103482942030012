import classNames from 'classnames';
import { months } from 'components/Receipts1S/const';
import { FieldUploadFileInput } from 'components/redesign/FieldUploadFileInput';
import { FileBusinessType } from 'enums/fileBusinessType';
import { FC, useMemo } from 'react';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { RecieptsByPeriodFormFieldNames, RecieptsByPeriodFormProps } from './types';

const recieptsByPeriodLimit = 10;

export const RecieptsByPeriodForm: FC<RecieptsByPeriodFormProps> = ({
  control,
  onSubmit,
  className,
  filters,
  watch,
}) => {
  const firstPartMonthInfo = useMemo(
    () =>
      `Первая половина месяца (оплата до 25.${months[filters.month.value - 1].infoValue}.${
        filters.year.value
      })`,
    [filters]
  );

  const secondPartMonthInfo = useMemo(
    () =>
      `Вторая половина месяца (оплата до 10.${
        months[filters.month.value === 12 ? 0 : filters.month.value].infoValue
      }.${filters.year.value + (filters.month.value === 12 ? 1 : 0)})`,
    [filters]
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      className={classNames('relative flex h-full flex-col', className)}
    >
      <div className="field-upload-input-wrapper">
        <div className="field-upload-input-item w-full max-w-[550px]">
          <FieldUploadFileInput
            name={RecieptsByPeriodFormFieldNames.FirstPartMonthFiles}
            control={control}
            label={firstPartMonthInfo}
            fileBusinessTypeId={FileBusinessType.Billing__ReceiptData}
            maxFileSize={10}
            uploadLimit={recieptsByPeriodLimit}
            filters={filters}
          />
        </div>
        <div className="field-upload-input-item w-full max-w-[550px]">
          <FieldUploadFileInput
            name={RecieptsByPeriodFormFieldNames.SecondPartMonthFiles}
            control={control}
            label={secondPartMonthInfo}
            fileBusinessTypeId={FileBusinessType.Billing__ReceiptData}
            maxFileSize={10}
            uploadLimit={recieptsByPeriodLimit}
            filters={filters}
          />
        </div>
      </div>
      <div>
        <Button
          variant={ButtonVariant.SUCCESS}
          className="btn-accent sm: mb-6 w-full max-w-[640px] self-center sm:max-w-[230px]"
          type="submit"
          disabled={
            !(watch('firstPartMonthFiles')?.length || watch('secondPartMonthFiles')?.length)
          }
        >
          СОХРАНИТЬ ЧЕКИ
        </Button>
      </div>
    </form>
  );
};
